import {useEffect, useState} from 'react';
import { ArrowRightCircle } from "react-bootstrap-icons";
import {Container, Row, Col, Button} from 'react-bootstrap';
import bannerImg from '../bannerImg.svg';

export const Banner = () => {

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <span className="tagLine">Welcome to my Resume</span>
                        <h1>{'Christopher Pereira '} <br/></h1>
                        <p>Hello! I am a third year university student majoring in Computer Science. My studies have given me a strong foundation in the world of software development and web design. I've acquired a deep understanding in C++, HTML, CSS, JavaScript, and frontend frameworks and libraries like React and Bootstrap. I am naturally curious and always seek to gain more experience and learn from others. See some of my skills and projects. </p>
                        <a href="#contact"><Button variant = 'light'>
                            Connect with me
                            <ArrowRightCircle/>
                        </Button></a>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                      <img src={bannerImg} alt="header img"/>  
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
