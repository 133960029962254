import { useState } from 'react';
import emailjs from '@emailjs/browser';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import ContactImg from '../ContactImg.svg';
export const Contact = () => {
    const formInitialDetails = {
        fullName: '',
        email: '',
        message: ''
    }

    const [formDetails,setFormDetails] = useState(formInitialDetails);
    const [buttonText,setButtonText] = useState('Send');
    const [status, setStatus] = useState({});
    const onFormUpdate = (category,value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        })
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        setButtonText('Sending...');

        //EmailJS service ID, templateID, Public Key
        const serviceID = 'service_qrmdogh';
        const templateID = 'template_hyollki';
        const publicKey = 'OfaPrHvQMvgRAUap3';

        //Email Object Properties
        const templateParams = {
            from_name: formDetails.fullName,
            from_email: formDetails.email,
            message: formDetails.message
        };

        //Send email using Emailjs

        emailjs.send(serviceID, templateID, templateParams, publicKey)
        .then((result) => {
            console.log('Email Sent!', result);
            setFormDetails(formInitialDetails);
            setButtonText('Sent!');
        })
        .catch((error) => {
            console.error('Error sending message.', error);
        });
    };
    return (
        <section className='contact' id='contact'>
            <Container>
                <Row className='align-items-center'>
                    <Col md={6}>
                        <img src={ContactImg} alt='Contact Me'/>
                    </Col>
                    <Col md={6}>
                        <h2>Contact Me</h2>
                        <form className="justify-content-end "onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={6} className='px-1'>
                                    <input type='text' value={formDetails.fullName} placeholder='Name' onChange={(e) => onFormUpdate('fullName',e.target.value)} />
                                </Col>
                                
                                
                                <Col sm={6} className='px-1'>
                                <input type='email' value={formDetails.email} placeholder='Email' onChange={(e) => onFormUpdate('email',e.target.value)} />
                                </Col>

                                <Col>
                                    <textarea row='6' value={formDetails.message} placeholder='Message...' onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                                    <button type='submit'><span>{buttonText}</span></button>
                                </Col>
                                
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>


    )
}