import { Container, Row, Col } from "react-bootstrap";
import myLogo1 from '../myLogo1.svg';
import navIcon1 from '../navIcon1.svg';
import navIcon2 from '../navIcon2.svg';
import navIcon3 from '../navIcon33.svg';

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-items-center mt-3 ">
                    <Col sm={6}>
                        <a href="#"><img className="align-items-left" src={myLogo1}  alt="Logo" /></a>
                    </Col>
                    <Col sm={6} className="text-center text-sm-end">
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/in/christopher-pereira-8418a8219" target="_blank"><img src={navIcon1}/></a>
                            <a href="https://www.facebook.com/profile.php?id=100028576715250"  target="_blank"><img src={navIcon2}/></a>
                            <a href="https://github.com/cpereira12" target="_blank"><img src={navIcon3}/></a>
                        </div>
                        <p>Copyright 2023. All Rights Reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}