import { Container, Row, Col, } from 'react-bootstrap';
import projImg from '../skills-icon.svg';
import { Tab, Nav } from 'react-bootstrap';
import {ProjectCard} from './ProjectCard';
import audioPluginImg from '../audioPluginImg.png';

export const Projects = () => {
    const projects = [
        {
        title: "Audio Plugin",
        description: "3 Band Equalizer plugin with real-time visual. Developed using C++ and the JUCE Framework.",
        imgUrl: audioPluginImg,
        }];

    return (
        <section className='project' id="projects">
            <Container>
                <Row>
                    <Col>
                    <h2>My Projects</h2>
                    <p>{projects.description}</p>
                    <Tab.Container id='projects-tabs' defaultActiveKey="first">
                        <Nav variant='pills' className='nav-pills mb-5 justify-content-center align-items-center' id="pills-tab">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Current Project</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second"className='nav-disabled' disabled >Project 2</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third"className='nav-disabled' disabled >Project 3</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <Row>
                                    {
                                        projects.map((project,index) => {
                                            return (
                                                <ProjectCard key={index}
                                                {...project}/>
                                            )
                                        })
                                    }
                                    <ProjectCard/>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                second
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                third
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}